import { EventEmitter, Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { Subscription, interval } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { TranslationService } from '../../../../../../Packages/npm/moondesk-web/projects/moondesk-web-lib/src/public_api';
import { AskDialogComponent } from '../web2/modules/shared/components/ask-dialog/ask-dialog.component';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class UpdateService {

  private checkForUpdatesSubscription: Subscription;
  private subscriptions: Subscription[] = [];
  hasNewVersion: EventEmitter<boolean> = new EventEmitter<boolean>();
  runWorkerUpdate: EventEmitter<boolean> = new EventEmitter<boolean>();
  constructor(
    public updates: SwUpdate,
    private dialog: MatDialog,
    private translationService: TranslationService,
    private router: Router)
  {
    if (updates.isEnabled)
    {
      this.checkForUpdatesSubscription = interval(5 * 60000).subscribe(() => updates.checkForUpdate()
        .then(() => console.log('checking for updates')));
    }
    this.subscriptions.push(this.runWorkerUpdate.subscribe(
      (run) => this.handleUpdate(run)));
    this.runWorkerUpdate.subscribe()
  }

  public checkForUpdates(): void
  {
    console.log('checking for updates');
    this.updates.versionUpdates.subscribe(event =>
    {
      if (event.type === 'VERSION_READY')
      {
        this.hasNewVersion.emit(true);
      }
    });
  }

  private handleUpdate(run: boolean)
  {
    if(this.router.url.indexOf('/mobile') >= 0)
    {
      this.promptMobileUser();
    }
    else
    {
      this.promptUser(run);
    }
  }
  public async promptUser(run: boolean)
  {
    if (run)
    {
      console.log('updating to new version');
      await this.cleanCache();
      this.updateServiceWorker();
    }
    else
    {
      this.checkForUpdatesSubscription?.unsubscribe();
      this.hasNewVersion.emit(false);
    }
  }

  private updateServiceWorker()
  {
    if ('serviceWorker' in navigator)
    {
      navigator.serviceWorker.register('/ngsw-worker.js').then(function(registration)
      {
        registration.update().then(() => document.location.reload());
      }).catch(function(error)
      {
        console.log('Error updating service worker:', error);
      });
    }
  }

  private async cleanCache()
  {
    try
    {
      const keys = await caches.keys();
      for (const key of keys)
      {
        await caches.delete(key);
      }
      console.log('Service Worker cache cleared');
    }
    catch (err)
    {
      console.error('Error clearing Service Worker cache:', err);
    }
  }

  public async promptMobileUser()
  {
    const title = this.translationService.getTranslation('lid.services.update.newVersionTitle');
    const info = this.translationService.getTranslation('lid.services.update.newVersionInfo');
    const answer = await this.ask(info, title);
    if (answer)
    {
      console.log('updating to new version');
      await this.cleanCache();
      this.updateServiceWorker();
    }
    else
    {
      this.checkForUpdatesSubscription.unsubscribe();
    }
  }

  private ask(question: string, title: string): Promise<boolean>
  {
    return new Promise<boolean>((resolve) =>
    {
      const dialogRef = this.dialog.open(AskDialogComponent, {
        width: '400px',
        minWidth: '260px',
        disableClose: true,
        data: {
          title: title,
          question: question,
        }
      });
      dialogRef.afterClosed().subscribe(result => resolve(result && result === 'yes'));
    });
  }
}
