import { animate, keyframes, state, style, transition, trigger } from '@angular/animations';
import { Component } from '@angular/core';
import { OnErrorAction } from '../feedback-helper.service';

@Component({
    selector: 'app-feedback-notifications',
    templateUrl: './feedback-notifications.component.html',
    styleUrls: ['./feedback-notifications.component.scss'],
    animations: [
        trigger('flyInOut', [
            state('inactive', style({
                opacity: 0,
            })),
            transition('inactive => active', animate('400ms ease-out', keyframes([
                style({
                    transform: 'translate3d(100%, 0, 0) skewX(-30deg)',
                    opacity: 0,
                }),
                style({
                    transform: 'skewX(20deg)',
                    opacity: 1,
                }),
                style({
                    transform: 'skewX(-5deg)',
                    opacity: 1,
                }),
                style({
                    transform: 'none',
                    opacity: 1,
                }),
            ]))),
            transition('active => removed', animate('400ms ease-out', keyframes([
                style({
                    opacity: 1,
                }),
                style({
                    transform: 'translate3d(100%, 0, 0) skewX(30deg)',
                    opacity: 0,
                }),
            ]))),
        ]),
    ],
    standalone: false
})
export class FeedbackNotificationsComponent {

  style: 'soft warning' | 'successfull' | 'warning' | 'severity warning' = 'successfull';
  message: string;
  action: OnErrorAction;

  dismiss()
  {
    // Function is defined into feeback service
  }

  contactSupport()
  {
    // Function is defined into feeback service
  }
}
