import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { FindState, NgxExtendedPdfViewerService, TextLayerRenderedEvent } from 'ngx-extended-pdf-viewer';
import { debounceTime, Subject, takeUntil } from 'rxjs';

export interface PdfTextLayerData
{
  textLayers: Map<number, HTMLElement>;
  fullText: string;
}

export interface ScrollPosition
{
  scrollTop: number;
  scrollLeft: number;
}

@Component({
    selector: 'app-moon-pdf-viewer',
    templateUrl: './moon-pdf-viewer.component.html',
    styleUrls: ['./moon-pdf-viewer.component.scss'],
    standalone: false
})
export class MoonPdfViewerComponent implements AfterViewInit, OnDestroy
{
  @Input() src!: string;
  @Input() page: number = 1;
  @Input() showAllPages: boolean;
  @Input() panOnClickDrag: boolean = true;
  @Input() set searchText(text: string)
  {
    this.search(text);
  }
  @Input() enableParagraphSelection: boolean = false;

  @Input() pdfJsViewer: boolean = false;

  @Input() set zoomLevel(zoom: number | string)
  {
    this._zoom = zoom;
  }
  @Output() zoomLevelChange: EventEmitter<number | string> = new EventEmitter<number | string>();

  @Input() set scrollPosition(scroll: ScrollPosition)
  {
    if (this.viewerContainer)
    {
      this.viewerContainer.scrollTo(scroll.scrollLeft, scroll.scrollTop);
    }
  }
  @Output() scrollChange: EventEmitter<ScrollPosition> = new EventEmitter<ScrollPosition>();


  @Output() pageRendered: EventEmitter<HTMLCanvasElement> = new EventEmitter<HTMLCanvasElement>();
  @Output() totalPages: EventEmitter<number> = new EventEmitter<number>();
  @Output() textLayerRendered: EventEmitter<PdfTextLayerData> = new EventEmitter<PdfTextLayerData>();
  @Output() searchFound: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() textSelected: EventEmitter<string> = new EventEmitter<string>();

  textLayers: Map<number, HTMLElement> = new Map<number, HTMLElement>();
  // private pdfTextLayer: HTMLElement;
  private destroy$ = new Subject<void>();
  private clickSubject = new Subject<MouseEvent>();

  // Obsolete
  zoom: number = 1;

  _zoom: number | string = 'auto';
  isDragging: boolean;
  busy = true;

  private viewerContainer: HTMLElement | null = null;

  constructor(private ngxExtendedPdfViewerService: NgxExtendedPdfViewerService, private el: ElementRef)
  {}



  ngAfterViewInit(): void
  {
    // Listen to click events on the pdf text layer
    this.clickSubject.pipe(debounceTime(100), takeUntil(this.destroy$)).subscribe((event: MouseEvent) =>
    {
      const target = event.target as HTMLElement;

      if (target.classList.contains('textLayer'))
      {
        return;
      }


      if (this.enableParagraphSelection)
      {
        this.highlightParagraph(target);
      }
    });
  }

  ngOnDestroy()
  {
    if (this.viewerContainer)
    {
      this.viewerContainer.removeEventListener('scroll', this.onScroll);
    }
    this.destroy$.next();
    this.destroy$.complete();
  }

  onPdfLoaded()
  {
    this.viewerContainer = document.getElementById('viewerContainer');
    if (this.viewerContainer)
    {
      // Just in case
      this.viewerContainer.removeEventListener('scroll', this.onScroll);

      this.viewerContainer.addEventListener('scroll', this.onScroll);
    }
  }

  private onScroll = (event: Event): void =>
  {
    const target = event.target as HTMLElement;
    this.scrollChange.emit({ scrollTop: target.scrollTop, scrollLeft: target.scrollLeft });
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onDrag(event: MouseEvent, pdfViewer: any)
  {
    if (!this.panOnClickDrag)
    {
      return;
    }
    if (this.isDragging)
    {
      const pdfContainer = pdfViewer.element.nativeElement.children[0] as HTMLElement;
      const x = pdfContainer.scrollLeft - event.movementX;
      const y = pdfContainer.scrollTop - event.movementY;
      pdfContainer.scrollTo(x, y);
    }
  }

  onDragStarted()
  {
    this.isDragging = true;
  }

  onDragEnded()
  {
    this.isDragging = false;
  }

  onZoomChange(event: number | string)
  {
    this._zoom = event;
    this.zoomLevelChange.emit(event);
  }

  onMouseWheel(event: WheelEvent)
  {
    event.preventDefault();
    if (event.deltaY < 0)
    {
      this.zoomIn();
    }
    else
    {
      this.zoomOut();
    }
  }

  /**
   * https://github.com/VadimDez/ng2-pdf-viewer#page-rendered
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onPageRendered(event: any)
  {
    if (event.pageNumber === this.page)
    {
      this.busy = false;
      this.pageRendered.emit(event.source.canvas);
    }
  }

  /**
   * https://github.com/VadimDez/ng2-pdf-viewer#after-load-complete
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onLoadComplete(event: any)
  {
    this.totalPages.emit(event._pdfInfo.numPages);
  }

  zoomIn()
  {
    if (this.zoom < 8)
    {
      this.zoom += 0.25;
    }
  }

  zoomOut()
  {
    if (this.zoom >= 0.50)
    {
      this.zoom -= 0.25;
    }
  }

  search(text: string)
  {
    this.ngxExtendedPdfViewerService.find(text,
    {
      highlightAll: true,
      matchCase: false,
      wholeWords: false,
    });
  }

  async onTextLayerRendered(event: TextLayerRenderedEvent)
  {
    this.textLayers.set(event.pageNumber, event.layer.div);

    const fullText = await this.getAllText();
    const pdfTextLayerData: PdfTextLayerData = { textLayers: this.textLayers, fullText };

    this.listenToClickEvents();

    this.textLayerRendered.emit(pdfTextLayerData);
  }

  updateFindState(event: FindState)
  {
    if (event === FindState.NOT_FOUND)
    {
      this.searchFound.emit(false);
    }
    else if (event === FindState.FOUND)
    {
      this.searchFound.emit(true);
    }
  }

  toggleParagraphSelection()
  {
    this.enableParagraphSelection = !this.enableParagraphSelection;
    if (this.enableParagraphSelection)
    {
      this.listenToClickEvents();

      // ToDo: Not working as intended - To be fixed
      // this.textLayers.forEach((layer) => layer.classList.add('pointer-mode'));
    }
  }

  private async getAllText()
  {
    const text = await this.ngxExtendedPdfViewerService.getPageAsText(1);
    return text;
  }

  private listenToClickEvents()
  {
    if (this.textLayers)
    {
      this.textLayers.forEach((layer) =>
      {
        layer.addEventListener('click', (event: MouseEvent) => { this.clickSubject.next(event); });
      });
    }
  }

  private highlightParagraph(target: HTMLElement)
  {
    this.clearHighlights();
    const paragraphSpans = this.getParagraphSpans(target);
    paragraphSpans.forEach((span) => span.classList.add('highlight'));

    let text = paragraphSpans.map((span) => span.textContent).join(' ');
    text = text.replace(/\s+/g, ' ').trim();
    this.textSelected.emit(text);
  }

  private clearHighlights()
  {
    const highlightedSpans = this.el.nativeElement.querySelectorAll('.highlight');
    highlightedSpans.forEach((span) => span.classList.remove('highlight'));
  }

  private getParagraphSpans(target: HTMLElement): HTMLElement[]
  {
    if (this.textLayers.size === 0)
    {
      return [];
    }
    let spansToAnalyze: HTMLElement[] = [];
    console.log(this.textLayers);
    this.textLayers.forEach((layer) =>
    {
      if (!layer)
      {
        return;
      }
      spansToAnalyze = spansToAnalyze.concat(Array.from(layer.querySelectorAll('span')));
    });
    spansToAnalyze = spansToAnalyze.filter((span) => !span.classList.contains('markedContent'));
    spansToAnalyze.splice(spansToAnalyze.indexOf(target), 1);

    const nearbySpans: Set<HTMLElement> = new Set([]);
    const queue: HTMLElement[] = [target];
    const threshold = this.getThreshold(spansToAnalyze);

    while (queue.length > 0)
    {
      const currentTarget = queue.shift();
      spansToAnalyze = spansToAnalyze.filter(span => span !== currentTarget);
      nearbySpans.add(currentTarget);
      const newNearbySpans = this.getNearbySpans(currentTarget, spansToAnalyze, threshold.thresholdTop, threshold.thresholdLeft);

      for (const span of newNearbySpans)
      {
        spansToAnalyze = spansToAnalyze.filter(s => span !== s);

        let isDuplicated: boolean = false;
        nearbySpans.forEach((nearbySpan) =>
        {
          const spanRect = span.getBoundingClientRect();
          const nearbySpanRect = nearbySpan.getBoundingClientRect();

          if (spanRect.top === nearbySpanRect.top && spanRect.left === nearbySpanRect.left)
          {
            isDuplicated = true;
            return;
          }
        });

        if (!isDuplicated)
        {
          nearbySpans.add(span);
          queue.push(span);
        }
      }
    }

    const orderedNearbySpans = Array.from(nearbySpans).sort((a, b) =>
    {
      const rectA = a.getBoundingClientRect();
      const rectB = b.getBoundingClientRect();

      const margin = 2; // Consider a small margin for top comparison

      if (Math.abs(rectA.top - rectB.top) <= margin)
      {
        return rectA.left - rectB.left;
      }
      return rectA.top - rectB.top;
    });

    return Array.from(orderedNearbySpans);
  }

  private getNearbySpans(target: HTMLElement, spans: HTMLElement[], maxDistanceY: number, maxDistanceX: number): HTMLElement[]
  {
    maxDistanceX = maxDistanceX > 0 ? maxDistanceX : maxDistanceY;
    if (!spans || spans.length === 0)
    {
      return [];
    }

    const targetRect = target.getBoundingClientRect();

    const closestSpans = spans.filter((span) =>
    {
      const spanRect = span.getBoundingClientRect();
      const isCloseHorizontally =
        Math.abs(spanRect.left - targetRect.left) < maxDistanceX ||
        Math.abs(spanRect.left - targetRect.right) < maxDistanceX ||
        Math.abs(spanRect.right - targetRect.left) < maxDistanceX;
      const isCloseVertically = Math.abs(spanRect.top - targetRect.top) < maxDistanceY;

      return isCloseHorizontally && isCloseVertically;

    });
    return closestSpans;
  }

  /**
   * Analyzes the distance between spans and calculates the average for
   * those that are close (distance less than 100px and greater than 1px)
   */
  private getThreshold(spans: HTMLElement[]): { thresholdTop: number; thresholdLeft: number }
  {
    if (spans.length < 2)
    {
      return { thresholdTop: 0, thresholdLeft: 0 };
    }

    let totalTopDistance = 0;
    let totalLeftDistance = 0;
    let countT = 0;
    let countL = 0;

    for (let i = 0; i < spans.length - 1; i++)
    {
      const rect1 = spans[i].getBoundingClientRect();
      const rect2 = spans[i + 1].getBoundingClientRect();

      const topDistance = Math.abs(rect2.top - rect1.top);
      if (topDistance > 1 && topDistance < 100)
      {
        totalTopDistance += topDistance;
        countT++;
      }
      const leftDistance = Math.abs(rect2.left - rect1.left);
      // Texts inside the same paragraph should not be too far apart horizontally
      if (leftDistance > 1 && leftDistance < 30)
      {
        totalLeftDistance += leftDistance;
        countL++;
      }
    }

    const thresholdTop = totalTopDistance / countT;
    const thresholdLeft = totalLeftDistance / countL;

    return { thresholdTop, thresholdLeft };
  }
}
