<div class="moon-pdf-viewer">
    <ng-container *ngIf="!pdfJsViewer">
        <div *ngIf="busy" class="spinner">
            <mat-progress-spinner  diameter="48" color="accent" mode="indeterminate"></mat-progress-spinner>
        </div> 
        <pdf-viewer #pdfViewer class="pdf-viewer" [src]="src" [ngClass]="{'hand-cursor': panOnClickDrag}"
            [render-text]="false"
            [original-size]="false"
            [zoom]="zoom"
            [show-borders]="false"
            [zoom-scale]="'page-fit'"
            [page]="page"
            [show-all]="showAllPages"
            (page-rendered)="onPageRendered($event)"
            (after-load-complete)="onLoadComplete($event)"
            (wheel)="onMouseWheel($event)"
            (mousemove)="onDrag($event, pdfViewer)"
            (mousedown)="onDragStarted()"
            (mouseup)="onDragEnded()"
            (mouseleave)="onDragEnded()">
        </pdf-viewer>    
    </ng-container>

    <ng-container *ngIf="pdfJsViewer">
        <ngx-extended-pdf-viewer class="pdf-viewer" [src]="src"
            [showToolbar]="true"
            [customToolbar]="additionalButtons"
            [showOpenFileButton]="true"
            [showZoomButtons]="true"
            [showZoomDropdown]="true"
            [handTool]="false"
            [showFindButton]="true"
            [showPropertiesButton]="true"
            [textLayer]="true"
            [enableDragAndDrop]="true"
            [zoom]="_zoom"
            (zoomChange)="onZoomChange($event)"
            (pdfLoaded)="onPdfLoaded()"
            (textLayerRendered)="onTextLayerRendered($event)"
            (updateFindState)="updateFindState($event)">
        </ngx-extended-pdf-viewer>

        <ng-template #additionalButtons>
            <div id="toolbarViewer">
              <div id="toolbarViewerLeft">
                <pdf-toggle-sidebar></pdf-toggle-sidebar>
                <pdf-search-input-field></pdf-search-input-field>
                <!-- <div class="toolbarButtonSpacer"></div> -->
                <!-- <pdf-paging-area></pdf-paging-area> -->
              </div>
              <pdf-zoom-toolbar ></pdf-zoom-toolbar> 
              <div id="toolbarViewerRight">
                <pdf-shy-button
                    [cssClass]="'always-visible' | responsiveCSSClass"
                    title="Paragraph selection"
                    primaryToolbarId="nestedComponent"
                    [order]="1"
                    [closeOnClick]="true">

                    <button #nestedContent
                        id="sidebarToggle"
                        color="primary"
                        class="toolbarButton"
                        matTooltip="Paragraph selection"
                        (click)="enableParagraphSelection = !enableParagraphSelection">
                        <mat-icon>highlight_alt</mat-icon>
                    </button>
                </pdf-shy-button>

                <pdf-open-file></pdf-open-file>
                <!-- <pdf-presentation-mode></pdf-presentation-mode> -->
                <!-- <pdf-print></pdf-print> -->
          
                <!-- <pdf-download></pdf-download> -->
                <!-- <div class="verticalToolbarSeparator hiddenSmallView"></div> -->
                <!-- <pdf-toggle-secondary-toolbar></pdf-toggle-secondary-toolbar> -->
              </div>
            </div>
        </ng-template>
    </ng-container>
</div>

