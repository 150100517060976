import { Pipe, PipeTransform } from '@angular/core';
import { Reviewer, TranslationService } from '../../../../../../../Packages/npm/moondesk-web/projects/moondesk-web-lib/src/public_api';

@Pipe({
    name: 'reviewerNaming',
    standalone: false
})
export class ReviewerNamingPipe implements PipeTransform {

  constructor(private translationService: TranslationService){}

  transform(reviewer: Reviewer, transformType?: 'fullNameWithGuest' | 'fullNameWithEmail' | 'fullName'): string
  {
    // Prevent errors when reviewer is not defined
    if(!reviewer)
    {
      return '';
    }
    // This should not happen, but if it does, we can use name or email as fullname.
    else if(!reviewer.fullname || reviewer.fullname === '')
    {
      reviewer.fullname = !reviewer.name || reviewer.name === '' ? reviewer.email : reviewer.name;
    }
    let value = '';
    switch(transformType)
    {
      case 'fullNameWithGuest':
        value = this.getFullNameWithGuest(reviewer);
        break;
      case 'fullNameWithEmail':
        value = this.getFullNameWithEmail(reviewer);
        break;
      case 'fullName':
        value = this.getFullName(reviewer);
        break;
      default:
        value = this.getFullNameWithGuest(reviewer);
        break;
    }
    return value;
  }


  private getFullNameWithGuest(reviewer: Reviewer): string
  {
    return `${reviewer.fullname} ${this.translationService.getTranslation('lid.pipes.reviewer-naming.guest')}`;
  }

  private getFullNameWithEmail(reviewer: Reviewer): string
  {
    return `${reviewer.fullname} (${reviewer.email})`;
  }

  private getFullName(reviewer: Reviewer): string
  {
    return `${reviewer.fullname}`;
  }
}
