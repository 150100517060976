import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  TextDifference,
  DiffType
} from '../../../../../../../../../../../Packages/npm/moondesk-web/projects/moondesk-web-lib/src/public_api';
import { PdfTextLayerData, ScrollPosition } from '../../moon-pdf-viewer/moon-pdf-viewer.component';

export interface TextCompareMessageToParent
{
  action: 'FULL_TEXT_READED' | 'READY' | 'SEARCH_NOT_FOUND' | 'SCROLL_CHANGE' | 'ZOOM_CHANGE' | 'TEXT_SELECTED';
  payload?: string | ScrollPosition | number;
  iframeIndex?: number;
}

export interface TextCompareMessageToChildren
{
  action:
    'SET_INDEX' |
    'SET_PDF_SRC' |
    'HIGHLIGHT_DIFFS' |
    'SEARCH_TEXT' |
    'HIGHLIGHT_DIFFS_IN_SELECTION' |
    'SET_SCROLL' |
    'SET_ZOOM';
  payload: string | TextDifference[] | ScrollPosition | number;
}

@Component({
  selector: 'app-pdf-viewer-iframe',
  templateUrl: './pdf-viewer-iframe.component.html',
  styleUrl: './pdf-viewer-iframe.component.scss',
  standalone: false
})
export class PdfViewerIframeComponent implements OnInit, OnDestroy
{
  private messageListener: (event: MessageEvent) => void;

  searchText: string = '';
  pdfSrc: string;
  textLayers: Map<number, HTMLElement>;
  // private textLayerData: PdfTextLayerData;
  scrollPosition: ScrollPosition;
  zoomLevel: number | string;

  iframeIndex = -1;
  ngOnInit()
  {
    this.messageListener = (event: MessageEvent) =>
    {
      if (event.origin !== window.location.origin)
      {
        return;
      }

      const data = event.data as TextCompareMessageToChildren;

      switch (data.action)
      {
        case 'SET_INDEX':
          this.iframeIndex = +data.payload;
          break;
        case 'SET_PDF_SRC':
          this.pdfSrc = data.payload as string;
          break;
        case 'HIGHLIGHT_DIFFS':
          this.highlightDifferences(data.payload as TextDifference[]);
          break;
        case 'SEARCH_TEXT':
          this.searchText = data.payload as string;
          break;
        case 'HIGHLIGHT_DIFFS_IN_SELECTION':
          this.highlightDifferencesInSelection(data.payload as TextDifference[]);
          break;
        case 'SET_SCROLL':
          this.scrollPosition = data.payload as ScrollPosition;
          break;
        case 'SET_ZOOM':
          this.zoomLevel = data.payload as number | string;
          break;
      }
    };

    window.addEventListener('message', this.messageListener);
    this.sendMsgToParent({ action: 'READY' });
  }

  ngOnDestroy()
  {
    if (this.messageListener)
    {
      window.removeEventListener('message', this.messageListener);
    }
  }

  onSearchFound(found: boolean)
  {
    if (!found)
    {
      const msg: TextCompareMessageToParent = { action: 'SEARCH_NOT_FOUND', payload: this.searchText, iframeIndex: this.iframeIndex };
      this.sendMsgToParent(msg);
    }
  }

  onScrollChange(event: ScrollPosition)
  {
    const msg: TextCompareMessageToParent = { action: 'SCROLL_CHANGE', payload: event, iframeIndex: this.iframeIndex };
    this.sendMsgToParent(msg);
  }

  onZoomLevelChange(zoom: number | string)
  {
    const msg: TextCompareMessageToParent = { action: 'ZOOM_CHANGE', payload: zoom, iframeIndex: this.iframeIndex };
    this.sendMsgToParent(msg);
  }

  onTextSelected(text: string)
  {
    const msg: TextCompareMessageToParent = { action: 'TEXT_SELECTED', payload: text, iframeIndex: this.iframeIndex };
    this.sendMsgToParent(msg);
  }

  async onTextLayerRendered(pdfTextLayerData: PdfTextLayerData)
  {
    this.textLayers = pdfTextLayerData.textLayers;
    const text = pdfTextLayerData.fullText;
    const msg: TextCompareMessageToParent = { action: 'FULL_TEXT_READED', payload: text, iframeIndex: this.iframeIndex };

    this.sendMsgToParent(msg);
  }

  private highlightDifferencesInSelection(diffs: TextDifference[])
  {
    const elements = this.textLayers.get(1);
    const selectedElements = elements.querySelectorAll('.highlight');
    this.highlightDifferences(diffs, selectedElements);
  }

  private highlightDifferences(differences: TextDifference[], elements?: NodeListOf<Element>)
  {
    this.removeHighlight();

    const diffTypeToDiscard = this.iframeIndex === 1 ? DiffType.Inserted : DiffType.Deleted;

    const splitDifferences = differences.filter(d => d.type !== diffTypeToDiscard);

    const firstPage = this.textLayers.get(1);
    const textElements = elements ?? firstPage.querySelectorAll('span');

    let generalIndex = 0;

    textElements.forEach((el: HTMLElement) =>
    {
      if (el.classList.contains('markedContent'))
      {
        return;
      }

      const text = el.textContent;
      let modifiedText = '';
      let lastSpan = null;

      for (let index = 0; index < text.length; index++)
      {
        const target = text[index];

        // Adjust dynamically if there are offsets.
        while (
          generalIndex < splitDifferences.length &&
          splitDifferences[generalIndex].text !== target &&
          splitDifferences[generalIndex].type !== DiffType.Equal
        )
        {
          generalIndex++;
        }

        const currentDifference = splitDifferences[generalIndex];

        if (!currentDifference)
        {
          break;
        }

        console.log(`Comparo: ${target} con: ${currentDifference.text} y la diferencia es: ${currentDifference.type}`);

        if (currentDifference.type !== DiffType.Equal)
        {
          const cssClass = currentDifference.type === DiffType.Inserted ? 'inserted' : 'deleted';

          if (lastSpan && lastSpan.class === cssClass)
          {
            lastSpan.content += target;
          }
          else
          {
            if (lastSpan)
            {
              modifiedText += `<span class="highlight ${lastSpan.class} selected appended">${lastSpan.content}</span>`;
            }
            lastSpan = { class: cssClass, content: target };
          }
        }
        else
        {
          if (lastSpan)
          {
            modifiedText += `<span class="highlight ${lastSpan.class} selected appended">${lastSpan.content}</span>`;
            lastSpan = null;
          }
          modifiedText += target;
        }

        generalIndex++;
      }

      if (lastSpan)
      {
        modifiedText += `<span class="highlight ${lastSpan.class} selected appended">${lastSpan.content}</span>`;
      }

      console.log(modifiedText);
      el.innerHTML = modifiedText;
    });
  }

  private removeHighlight(): void
  {
    const elements = this.textLayers.get(1);
    const selectedElements = elements.querySelectorAll('.highlight');
    selectedElements.forEach((element) =>
    {
      element.classList.remove('highlight', 'selected', 'inserted', 'deleted', 'appended');
    });
  }

  private sendMsgToParent(msg: TextCompareMessageToParent)
  {
    window.parent.postMessage(msg, '*');
  }
}
