import { Pipe, PipeTransform } from '@angular/core';
import { User } from '../../../../../../../Packages/npm/moondesk-web/projects/moondesk-web-lib/src/public_api';

@Pipe({
    name: 'userNaming',
    standalone: false
})
export class UserNamingPipe implements PipeTransform {

  transform(user: User, transformType?: 'fullName' | 'fullNameWithEmail' | 'usernameWithEmail'): string
  {
    let value = '';
    switch(transformType)
    {
      case 'fullName':
        value = this.getFullName(user);
        break;
      case 'fullNameWithEmail':
        value = this.getFullNameWithEmail(user);
        break;
      case 'usernameWithEmail':
        value = this.getUsernameWithEmail(user);
        break;
      default:
        value = this.getFullName(user);
        break;
    }
    return value;
  }


  private getFullName(user: User): string
  {
    return `${user.fullname}`;
  }

  private getFullNameWithEmail(user: User): string
  {
    return `${user.fullname} (${user.email})`;
  }

  private getUsernameWithEmail(user: User): string
  {
    return `${user.username} (${user.email})`;
  }


}
