<div class="pdf-viewer-iframe">
  <app-moon-pdf-viewer class="pdf-viewer"
    [src]="pdfSrc"
    [pdfJsViewer]="true"
    [searchText]="searchText"
    [scrollPosition]="scrollPosition"
    [zoomLevel]="zoomLevel"
    (textLayerRendered)="onTextLayerRendered($event)"
    (searchFound)="onSearchFound($event)"
    (scrollChange)="onScrollChange($event)"
    (zoomLevelChange)="onZoomLevelChange($event)"
    (textSelected)="onTextSelected($event)">
  </app-moon-pdf-viewer>
</div>